import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Link, Input, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Formspree } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				Контакти | Woods
			</title>
			<meta name={"description"} content={"Оснастіть свою арену елітною підняттям - вибір для футбольної майстерності."} />
			<meta property={"og:title"} content={"Контакти | Woods"} />
			<meta property={"og:description"} content={"Оснастіть свою арену елітною підняттям - вибір для футбольної майстерності."} />
			<meta property={"og:image"} content={"https://ua.woodsua.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.woodsua.com/img/soccer-icon-png-1.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.woodsua.com/img/soccer-icon-png-1.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.woodsua.com/img/soccer-icon-png-1.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.woodsua.com/img/soccer-icon-png-1.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.woodsua.com/img/soccer-icon-png-1.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.woodsua.com/img/soccer-icon-png-1.jpg"} />
			<meta name={"msapplication-TileColor"} content={"https://ua.woodsua.com/img/soccer-icon-png-1.jpg"} />
		</Helmet>
		<Components.Header />
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://ua.woodsua.com/img/3.jpg) 0% 0%/cover"
			quarkly-title="Form-1"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" md-width="100%">
					<Box>
						<Box sm-padding="64px 0 0 0" position="relative" text-align="left">
							<Text as="p" margin="6px 0" font="--headline1">
							Контакти
							</Text>
						</Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="360px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
								Відвідай нас
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								пр. Коцюбинського 30, м. Вінниця, 21000
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Надішліть нам електронну пошту
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="mailto:info@ua.woodsua.com" text-decoration="none" hover-text-decoration="underline" color="--light">
									info@ua.woodsua.com
								</Link>
							</Text>
						</Box>
						<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Зателефонуйте нам
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								0432 277 695
							</Text>
						</Box>
					</Box>
				</Box>
				<Box width="50%" padding="8px 8px 8px 8px" md-width="100%">
					
				</Box>
			</Box>
		</Section>
		<Components.Footer13 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});